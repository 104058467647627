import React from 'react';
import { useNavigate } from 'react-router-dom';

import image from '../assets/image26.svg';

import { useLocationContext } from '../context';

import { PrimaryButton, StationsList, Header, Footer, showErrorNotification } from '../components';

function Home() {
  const navigate = useNavigate();

  const { selectedLocation } = useLocationContext();

  const onRegister = () => {
    if (selectedLocation) {
      navigate('/registeroption');
    } else {
      showErrorNotification('Select your location first');
    }
  };

  return (
    <>
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen flex flex-col">
          <Header />
          <div className="px-4 py-6 w-full flex-1">
            <div className=" w-max mx-auto">
              <img className="m-auto" src={image} alt="" />
              <div className=" text-center text-xl font-semibold">Apki Kamai ka sabse asan rasta</div>
              <div className=" text-center text-base">Drive with GADI GODA</div>

              <StationsList />
              <PrimaryButton text="Register Now" handleOnclick={onRegister} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
