import { toast, Flip } from 'react-toastify';

const toastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
  transition: Flip,
};
export const showErrorNotification = message => toast.error(message, toastOptions);

export const showSuccessNotification = message => toast.success(message, toastOptions);
