import axios from 'axios';
import { compressImage } from '../compressImage';

export const uploadFile = async file => {
  let formData = new FormData();
  let compressedImage;
  // const sizeOfFile = file.size / 1024;
  // console.log('SIZE OF FILE IN KB ', sizeOfFile);
  if (file.size / 1024 >= 512) {
    compressedImage = await compressImage(file);
  } else {
    compressedImage = file;
  }
  formData.append('file', compressedImage);
  // console.log('SIZE OF COMPRESSED FILE IN KB ',compressedImage.size/1024)
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/uploadFile`, formData);
    console.log(response);
    let image_url = response.data.downloadUrl;
    return image_url;
  } catch (error) {
    console.log(error);
    return 'failed';
  }
};
