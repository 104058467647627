import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIndividualContext } from '../../context';
import ImageInput from '../../components/ImageInput';
import arrowLeft from '../../assets/ArrowLeft.svg';
import axios from 'axios';
import loadingGif from '../../assets/carLoading.gif';
import tickSvg from '../../assets/Group 427319018.svg';
import Error from '../../components/Error';
import { motion, AnimatePresence } from 'framer-motion';
import arrowDown from '../../assets/arrowdown-svgrepo-com.svg';
import { uploadFile } from '../../utils/fileUpload/imageUpload';
import { seats_array } from '../../utils/constants';

function VerifyRC() {
  const {
    contact_number,
    car_available,
    vehicle_number,
    num_plate_img,
    carrier_available,
    is_rc_verified,
    seats,
    dispatch,
  } = useIndividualContext();
  const [loading, setLoading] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rcData, setRcData] = useState(null);
  const navigate = useNavigate();

  const addCar = async (car_detail, isRCChecked) => {
    setLoading('Saving Car info');
    let car_info = {
      contact_number: contact_number,
      id: vehicle_number + '_' + contact_number,
      vehicle_number: vehicle_number,
      is_fleet_car: 'false',
      num_plate_img: num_plate_img,
      car_data: car_detail,
      is_rc_verified: isRCChecked,
      carrier_available: carrier_available,
      seats: seats,
    };
    if (!car_info.num_plate_img) {
      setErrorMessage('All details are required');
      return;
    }
    if (
      typeof car_info.num_plate_img !== 'string' ||
      !car_info.num_plate_img.includes('https://storage.cloud.google.com/app-gadigoda.appspot.com')
    ) {
      let response = await uploadFile(car_info.num_plate_img);
      console.log(response);
      if (response !== 'failed') {
        car_info.num_plate_img = response;
        dispatch({
          type: 'SET_DATA',
          payload: { name: 'num_plate_img', value: response },
        });
      } else {
        console.log(response);
        setErrorMessage('Image upload failed. Please try again');
      }
    } else {
      console.log(car_info.num_plate_img);
    }
    try {
      let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addCar`, car_info);
      console.log(response);
      setLoading(false);
      dispatch({
        type: 'SET_DATA',
        payload: { name: 'car_data', value: car_detail },
      });
      dispatch({
        type: 'SET_DATA',
        payload: { name: 'is_rc_verified', value: true },
      });
    } catch (e) {
      setErrorMessage('Error occured. Please try again');
      setLoading(false);
      console.log(e);
    }
  };

  const verifyRC = async () => {
    if (!vehicle_number || !num_plate_img || !seats) {
      setErrorMessage('All details are required');
      return;
    }
    const url = `https://kyc-api.surepass.io/api/v1/rc/rc-lite-v2`;
    setLoading('Verifying RC plate number');
    try {
      const response = await axios.post(
        url,
        { id_number: vehicle_number },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        console.log(response);
        addCar(response.data.data, true);
        setRcData(response.data.data);
      } else {
        setLoading(false);
        console.log('error', response);
        setErrorMessage('RC verification failed');
      }
    } catch (e) {
      setErrorMessage('RC verification failed');
      console.log(e);
    }
  };

  return (
    <>
      <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
          <div className="flex items-center gap-2">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => {
                navigate('/addindividual/verifyadhaar');
              }}
            />
            <div className=" text-base font-semibold">Adding new individual Partner</div>
          </div>
          <div className="mt-5 relative">
            <div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Enter and verify mobile number</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Enter and verify driver license</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Hi, complete your kyc</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <div className="  bg-yellow w-8 py-1 rounded-sm text-center">4</div>
              <div className=" text-sm">Let's add your car details</div>
            </div>
          </div>
          {loading === false ? (
            <div className=" pb-16">
              <div>
                <div className="flex items-center">
                  <input
                    className="w-4 h-4"
                    type="checkbox"
                    checked={!car_available}
                    id="car_available"
                    onChange={e =>
                      dispatch({
                        type: 'SET_DATA',
                        payload: {
                          name: 'car_available',
                          value: !e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="car_available" className="pl-2 text-sm">
                    I don’t have a car
                  </label>
                </div>
                <div className=" w-full max-w-[500px] h-[2px] my-4 bg-gray-300 "></div>
                {car_available && (
                  <>
                    <div className=" text-xs mb-1">
                      License Plate Number
                      <span className=" text-[#DC3545]">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Eg: MH091823"
                      value={vehicle_number}
                      className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                      onChange={e => {
                        dispatch({
                          type: 'SET_DATA',
                          payload: {
                            name: 'vehicle_number',
                            value: e.target.value,
                          },
                        });
                        dispatch({
                          type: 'SET_DATA',
                          payload: { name: 'is_rc_verified', value: false },
                        });
                      }}
                    />
                    <div className="relative">
                      <div
                        className="relative z-[2] p-3 py-3 bg-gray-100 w-full max-w-[500px] flex justify-between mt-2 rounded-md cursor-pointer"
                        onClick={() => {
                          setIsDropDownOpen(!isDropDownOpen);
                        }}
                      >
                        <div>{!seats ? 'Select seat count' : seats}</div>
                        <motion.img
                          animate={{
                            rotate: isDropDownOpen ? 180 : 0,
                          }}
                          src={arrowDown}
                          className="w-6 h-6"
                          alt=""
                        />
                      </div>

                      <AnimatePresence>
                        {isDropDownOpen && (
                          <motion.div
                            initial={{
                              y: '-8px',
                              opacity: 0,
                            }}
                            animate={{
                              opacity: 1,
                              y: '0px',
                            }}
                            exit={{
                              y: '-8px',
                              opacity: 0,
                            }}
                            className="absolute bg-white z-[1] max-h-[200px] overflow-scroll mt-3 rounded-md shadow-[0px_0px_5px_0px_rgba(0,0,0,0.3)] w-full max-w-[500px]"
                          >
                            {seats_array?.map(seat => {
                              return (
                                <div
                                  className="p-3 cursor-pointer hover:bg-gray-100"
                                  onClick={() => {
                                    dispatch({
                                      type: 'SET_DATA',
                                      payload: { name: 'seats', value: seat },
                                    });
                                    dispatch({
                                      type: 'SET_DATA',
                                      payload: { name: 'is_rc_verified', value: false },
                                    });
                                    // dispatch({
                                    //   type: "SET_DATA",
                                    //   payload: {
                                    //     name: "selected_location",
                                    //     value: station?.name,
                                    //   },
                                    // });
                                    setIsDropDownOpen(false);
                                  }}
                                  key={seat}
                                >
                                  {seat}
                                </div>
                              );
                            })}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <ImageInput
                      handleChange={e => {
                        let file = e.target.files[0];

                        dispatch({
                          type: 'SET_DATA',
                          payload: { name: 'num_plate_img', value: file },
                        });
                      }}
                      id="plate_img"
                      image={num_plate_img}
                      labelText="Upload License Plate Image"
                      imgText="license_plate"
                      handleDel={() => {
                        dispatch({
                          type: 'SET_DATA',
                          payload: { name: 'num_plate_img', value: '' },
                        });
                        dispatch({
                          type: 'SET_DATA',
                          payload: { name: 'is_rc_verified', value: false },
                        });
                      }}
                    />
                    <span className="text-[10px] text-yellow">Image size sholud be less than 1mb</span>
                    <div className="flex items-center mt-4">
                      <input
                        className="w-4 h-4"
                        type="checkbox"
                        checked={carrier_available}
                        id="carrier_available"
                        onChange={e => {
                          dispatch({
                            type: 'SET_DATA',
                            payload: {
                              name: 'carrier_available',
                              value: e.target.checked,
                            },
                          });
                          dispatch({
                            type: 'SET_DATA',
                            payload: { name: 'is_rc_verified', value: false },
                          });
                        }}
                      />
                      <label htmlFor="carrier_available" className="pl-2 text-sm">
                        Do your car have Carrier
                      </label>
                    </div>
                    {!is_rc_verified ? (
                      <button
                        className="mt-5 border-2 py-2 w-full max-w-[500px] rounded-md border-black"
                        onClick={() => {
                          verifyRC();
                        }}
                      >
                        Verify and Save
                      </button>
                    ) : (
                      <button className="mt-5 border-2 py-2 w-full max-w-[500px] rounded-md border-green-500 text-green-500">
                        Verified
                      </button>
                    )}
                  </>
                )}
              </div>
              {is_rc_verified && (
                <div className="flex mt-5 flex-col gap-1">
                  <span>
                    Name : <span className="font-semibold">{rcData.owner_name}</span>
                  </span>
                  <span>
                    Car Model : <span className="font-semibold">{rcData.maker_model}</span>
                  </span>
                  <span>
                    Fit Up To : <span className="font-semibold">{rcData.fit_up_to}</span>
                  </span>
                  <span>
                    Permit Number : <span className="font-semibold">{rcData.permit_number}</span>
                  </span>
                  <span>
                    RC Status : <span className="font-semibold">{rcData.rc_status}</span>
                  </span>
                  <span>
                    Pucc UpTo : <span className="font-semibold">{rcData.pucc_upto}</span>
                  </span>
                  <span>
                    Insurance UpTo : <span className="font-semibold">{rcData.insurance_upto}</span>
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className=" w-[250px] mx-auto mt-20">
              {/* if loading then show loading gif */}
              <img className="w-[165px] h-[130px] mx-auto" src={loadingGif} alt="" />
              <div className=" whitespace-normal text-xs text-gray-500 text-center">{loading}</div>
            </div>
          )}
          {loading === false && (is_rc_verified || !car_available) && (
            <div className="fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2">
              <button
                className=" bg-black text-white rounded-md px-8 py-2"
                onClick={() => {
                  navigate('/addindividual/addbankdetails');
                }}
              >
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default VerifyRC;
