import React, { useState } from 'react';
import ErrorImg from '../assets/regi_done.svg';
import Modal from 'react-modal';
import crossSvg from '../assets/Vector (1) copy.svg';
import { useNavigate } from 'react-router-dom';

function Done() {
  const customStyles = {
    content: {
      inset: '50% -100px -100px 50%',
      padding: '20px',
      transform: 'translate(-50%, -50%)',
      height: '50%',
      maxWidth: '400px',
    },
    overlay: {
      background: 'rgb(0 0 0 / 70%)',
      zIndex: '3',
    },
  };
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  return (
    <>
      <Modal isOpen={show === true ? true : false} style={customStyles}>
        <div className="flex justify-between">
          <div className=""></div>
          <img
            className=" cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
            src={crossSvg}
            alt=""
          />
        </div>
        <div className=" flex flex-col items-center">
          <div className="h-[half] pb-8">
            <img src={ErrorImg} alt="" />
          </div>
          <div className="text-black font-bold py-4 text-center">Registration Completed</div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <button
            className="border border-black rounded-md px-3 py-2 hover:bg-black hover:text-white"
            onClick={() => navigate('/')}
          >
            Return to Home
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Done;
