import React, { useEffect } from 'react';
import Img from '../../assets/regi_done.svg';
import { useNavigate } from 'react-router-dom';
import { useIndividualContext } from '../../context';
import axios from 'axios';
import { sendSms } from '../../apis/sms';

export default function Success() {
  const navigate = useNavigate();
  const { contact_number, dispatch } = useIndividualContext();

  async function sendMessgage() {
    await sendSms(contact_number);
  }

  useEffect(() => {
    sendMessgage();
  }, []);

  return (
    <>
      <div className="p-5 min-h-screen h-max">
        <div className="flex items-center justify-center min-h-[calc(100vh-40px)] h-max">
          <div className="flex-1">
            <img className="w-20 aspect-[1] mx-auto mb-4" src={Img} alt="" />
            <p className="font-semibold text-[1.1rem] text-center">Partner registered sucessfully</p>
            <p className="text-center font-light">We're Reviewing your document, and get back to you soon</p>
          </div>
        </div>
        <button
          onClick={() => {
            dispatch({ type: 'SET_TO_INITIAL' });
            navigate('/');
          }}
          className="bg-yellow py-2 w-[calc(100%-40px)] max-w-[500px] rounded-[7px] fixed bottom-5 left-1/2 -translate-x-1/2 "
        >
          Done
        </button>
      </div>
    </>
  );
}
