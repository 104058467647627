import { Typography } from '@mui/material';

export const StepHeader = ({ step, text }) => (
  <div className="flex gap-3 items-center my-5 z-[1] relative">
    <div className="bg-yellow w-8 py-1 rounded-sm text-center">{step}</div>
    <Typography component="div" variant="subtitle1">
      {text}
    </Typography>
  </div>
);
