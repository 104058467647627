import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDriverDetails } from '../apis';

function UserDetails() {
  const [data, setData] = useState(null);
  const { userId } = useParams();

  const getUserDetail = async () => {
    const response = await getDriverDetails(userId);
    if (response.data) {
      setData(response.data);
    }
  };

  useEffect(() => {
    getUserDetail(userId);
  }, [userId]);
  const cars = data?.cars;

  return (
    <div className="flex flex-col gap-4 m-10">
      <div className="flex flex-col gap-4 border-2 border-slate-300 p-8 rounded-xl shadow-xl w-[500px]">
        <div className="flex flex-col">
          <span className="font-semibold text-2xl">Name</span>
          <span className="text-2xl text-red-400">
            {' '}
            {data?.isfleet === 'true' ? data.name : data?.dl_number_data?.name}
          </span>
        </div>
        <div className="flex flex-col text-xl">
          <span className="font-semibold text-2xl">Number</span>
          <span className="text-2xl text-red-400"> {userId}</span>
        </div>
        <div className="flex flex-col">
          <span className="font-semibold text-2xl">Type</span>
          <span className="text-2xl text-red-400"> {data?.isfleet === 'true' ? 'Fleet' : 'Individual'}</span>
        </div>

        {data?.isfleet !== 'true' && (
          <div className="flex flex-col">
            <span className="font-semibold text-2xl">DL Number</span>
            <span className="text-2xl text-red-400"> {data?.dlnumber}</span>
          </div>
        )}

        {data?.aadhar_no && (
          <div className="flex flex-col">
            <span className="font-semibold text-2xl">Aadhar Number</span>
            <span className="text-2xl text-red-400"> {data?.aadhar_no}</span>
          </div>
        )}

        {data?.selected_location && (
          <div className="flex flex-col">
            <span className="font-semibold text-2xl">Location</span>
            <span className="text-2xl text-red-400"> {data?.selected_location}</span>
          </div>
        )}
      </div>

      <div className="text-3xl font-semibold">Car Details </div>
      <table>
        <thead>
          <tr className="border-2 border-green-500">
            <th className="p-2 border-2 border-green-500  bg-slate-200">Rc No</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Owner Name </th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Car Brand</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Car Name</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Seats</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Fit UpTo</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Pucc UpTo</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Insurance UpTo</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">T Permit</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">T Permit valid upto</th>
            <th className="p-2 border-2 border-green-500 bg-slate-200">Location</th>
          </tr>
        </thead>

        <tbody>
          {cars &&
            cars.map((el, i) => {
              return (
                <tr key={i} className="">
                  <td className="border border-gray-300 p-2">{el?.car_data?.rc_number} </td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.owner_name}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.maker_description}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.maker_model}</td>
                  <td className="border border-gray-300 p-2">{el?.seats}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.fit_up_to}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.pucc_upto}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.insurance_upto}</td>
                  <td className="border border-gray-300 p-2">{el?.car_data?.permit_number}</td>
                  <td className="border border-gray-300 p-1">{el?.car_data?.permit_valid_upto}</td>
                  <td className="border border-gray-300 p-1">
                    {el?.vehicle_location ? el?.vehicle_location : data?.selected_location}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default UserDetails;
