import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIndividualContext } from '../context/IndividualContext';
import { useFleetContext } from '../context/FleetContext';
import { getRequest } from '../utils/api';
import { showErrorNotification, StationsList } from '../components';
import { useLocationContext } from '../context';

function AdminList() {
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dispatch: individualDispatch } = useIndividualContext();
  const { dispatch: fleetDispatch } = useFleetContext();
  const { selectedLocation } = useLocationContext();
  // const [query, setQuery] = useState("");

  const goToDriver = ele => {
    if (ele.isfleet === 'true') {
      fleetDispatch({ type: 'SET_PARTNER_DATA_FLEET', payload: ele });
      // navigate("/addfleet/fleetverifyadhaar");
      navigate('/addfleet/dashboard');
      // navigate("/addfleet/addbankdetails");
    } else {
      individualDispatch({ type: 'SET_PARTNER_DATA', payload: ele });
      // navigate("/addindividual/verifydl");
      navigate('/addindividual/verifyadhaar');
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const getDrivers = async () => {
      setLoading(true);
      try {
        const response = await getRequest('getAllDrivers', {
          signal: abortController.signal,
        });
        setDrivers(response.data);
        setLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          showErrorNotification('Could not fetch all drivers');
          setLoading(false);
        }
      }
    };

    if (drivers.length === 0) {
      getDrivers();
    }

    return () => {
      abortController.abort();
    };
  }, [drivers.length]);

  // const getDriverFn = async () => {
  // 	setLoading(true);
  // 	const response = await getAllDrivers();
  // 	setDrivers(response);
  // 	setLoading(false);
  // };
  // getDriverFn();
  const statuses = [
    'All Drivers',
    'Aadhar Verified',
    'Aadhar Skiped',
    'Fleet',
    'Individual',
    'DL Number Verified',
    'Is Agreed',
  ];
  const [filterConditions, setFilterConditions] = useState([]);

  const changeFilterCondition = option => {
    if (option === 'All Drivers') {
      setFilterConditions([]);
    } else {
      let newFilterConditions = [];
      if (option === 'Aadhar Verified' || option === 'Aadhar Skiped') {
        newFilterConditions = filterConditions.filter(
          condn => condn !== 'Aadhar Verified' && condn !== 'Aadhar Skiped'
        );
      } else if (option === 'Fleet' || option === 'Individual') {
        newFilterConditions = filterConditions.filter(condn => condn !== 'Fleet' && condn !== 'Individual');
      }
      setFilterConditions([...newFilterConditions, option]);
    }
  };

  const filteredDrivers = useMemo(() => {
    let newList = drivers;

    if (selectedLocation) {
      newList = newList.filter(driver => driver?.selected_location === selectedLocation);
    }

    filterConditions.forEach(condition => {
      if (condition === 'Individual') {
        newList = newList.filter(driver => driver.isfleet === 'false');
      } else if (condition === 'Fleet') {
        newList = newList.filter(driver => driver.isfleet === 'true');
      } else if (condition === 'Aadhar Verified') {
        newList = newList.filter(driver => driver.aadhar_checked === true);
      } else if (condition === 'Aadhar Skiped') {
        newList = newList.filter(driver => driver.aadhar_checked === false);
      } else if (condition === 'DL Number Verified') {
        newList = newList.filter(driver => driver.dl_number_checked === true);
      } else if (condition === 'Is Agreed') {
        newList = newList.filter(driver => driver.is_agreed === true);
      }
    });

    return newList;
  },[drivers,filterConditions,selectedLocation]);


  return (
    <div className="m-8 flex flex-col gap-2 relative">
      <div className="p-2 -mt-6 flex items-center gap-24 h-16">
        <div className="flex gap-5">
          {statuses.map((el, i) => (
            <span
              className={`border p-2 cursor-pointer rounded-lg bg-yellow ${
                (el === 'All Drivers' && filterConditions.length === 0) || filterConditions.includes(el)
                  ? 'bg-green-400'
                  : ''
              }`}
              onClick={() => changeFilterCondition(el)}
              key={i}
            >
              {el}
            </span>
          ))}
        </div>
        <div className="w-[400px]">
          <StationsList />
        </div>
      </div>
      <table className="p-2">
        <thead className="relative">
          <tr className="sticky top-0">
            <th className="p-2 border bg-slate-200">No</th>
            <th className="p-2 border bg-slate-200">Name</th>
            <th className="p-2 border bg-slate-200">Contact</th>
            <th className="p-2 border bg-slate-200">Type</th>
            <th className="p-2 border bg-slate-200">BD Code</th>
            <th className="p-2 border bg-slate-200">Location</th>
            <th className="p-2 border bg-slate-200">Actions</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7" className="text-center py-4">
                <h1 className="text-4xl text-yellow">Loading.....</h1>
              </td>
            </tr>
          ) : (
            <>
              {filteredDrivers &&
                filteredDrivers.map((ele, i) => (
                  <tr key={i}>
                    <td className="border border-gray-300 p-2">{i + 1}</td>
                    <td className="border border-gray-300 p-2">
                      {ele.isfleet === 'true' ? ele.name : ele?.dl_number_data?.name}
                    </td>
                    <td className="border border-gray-300 p-2">{ele.contact_number}</td>
                    <td className="border border-gray-300 p-2">{ele.isfleet === 'true' ? 'Fleet' : 'Individual'}</td>
                    <td className="border border-gray-300 p-2">{ele?.bdCode}</td>
                    <td className="border border-gray-300 p-2">{ele?.selected_location}</td>
                    <td className="border border-gray-300 p-2 space-x-4">
                      <button className="bg-yellow px-2 rounded-md" onClick={() => goToDriver(ele)}>
                        Go
                      </button>
                      <button className="bg-green-600 px-2 rounded-md">
                        <Link to={`/userDetail/${ele.id}`}>User</Link>
                      </button>
                    </td>
                  </tr>
                ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AdminList;
