import React, { createContext, useState, useContext } from 'react';

const LocationContext = createContext();

export const useLocationContext = () => {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error('useLocationContext must be used inside a LocationContextProvider');
  }

  return context;
};

export const LocationContextProvider = ({ children }) => {
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  return (
    <LocationContext.Provider value={{ location, setLocation, selectedLocation, setSelectedLocation }}>
      {children}
    </LocationContext.Provider>
  );
};
