import { createContext, useReducer, useContext } from 'react';

export const IndividualContext = createContext();
const initialState = {
  selected_location: '',
  contact_number: '',
  dlnumber: '',
  dob: '',
  dl_front_img: '',
  dl_back_img: '',
  dl_number_checked: false,
  dl_number_data: '',
  partner_photo: '',
  pan_no: '',
  pan_img: '',
  aadhaar_no: '',
  aadhaar_front_img: '',
  aadhaar_back_img: '',
  aadhar_data: '',
  aadhar_checked: false,
  aadhar_skipped: false,
  car_available: true,
  vehicle_number: '',
  num_plate_img: '',
  seats: '',
  carrier_available: false,
  car_data: '',
  is_rc_verified: '',
  is_agreed: false,
  bank_details: {
    full_name: '',
    bank_name: '',
    account_type: '',
    account_no: '',
    branch_name: '',
    ifsc: '',
  },
  gst_number: '',
  bdCode: '',
};
export const individualReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_TO_INITIAL':
      return { ...initialState };
    case 'SET_PARTNER_DATA':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const useIndividualContext = () => {
  const context = useContext(IndividualContext);

  if (!context) {
    throw Error('useIndividual must be used inside an IndividualContextProvider');
  }

  return context;
};
export const IndividualContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(individualReducer, initialState);
  return <IndividualContext.Provider value={{ ...state, dispatch }}>{children}</IndividualContext.Provider>;
};
