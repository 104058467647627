import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Onboard() {
  const [dlnumber, setDLNumber] = useState(null);
  const [keydata, setKeyData] = useState(null);
  const navigate = useNavigate();

  async function addKeyPerson() {
    const url = ``; // url for addDriver Firebase function
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          data: keydata,
          type: 'key',
        },
      });
      if (response.status === 200) console.log('Key Person Added');
      else console.log('error');
    } catch {
      console.log('error');
    }
  }

  async function handleOnboard() {
    const url = ``;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: dlnumber,
      });

      if (response.message_code === 'success') {
        console.log('Verified');
        localStorage.setItem('dlnumber', dlnumber);
        setKeyData(response.data);
        addKeyPerson();
        navigate('/addvehicle');
      } else {
        console.log('Not Verified');
      }
    } catch {
      console.log('Error Occured');
    }
  }

  return (
    <>
      <label>Driving Licence Number</label>
      <input type="text" onChange={e => setDLNumber(e)} />
      <button type="button" onClick={handleOnboard}>
        Join Us
      </button>
    </>
  );
}
