import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { EntityProvider } from './components/EntityContext';
import { IndividualContextProvider, FleetContextProvider, LocationContextProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <EntityProvider>
      <LocationContextProvider>
        <FleetContextProvider>
          <IndividualContextProvider>
            <Router>
              <App />
              <ToastContainer />
            </Router>
          </IndividualContextProvider>
        </FleetContextProvider>
      </LocationContextProvider>
    </EntityProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
