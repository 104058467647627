import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { useIndividualContext } from '../../context';

import otpSvg from '../../assets/Group 427319036.jpg';

import {
  BackNav,
  showErrorNotification,
  LoaderScreen,
  StepHeader,
  SecondaryButton,
  PageTemplate,
} from '../../components';

import { sendMobileOTP, verifyMobileOTP, addDriver } from '../../apis';

function AddIndividual() {
  const navigate = useNavigate();
  const { contact_number, dispatch } = useIndividualContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState('');
  const [OTPRef, setOTPRef] = useState('');
  const [isNewUser, setisNewUser] = useState(true);
  const [mobileOTP, setMobileOTP] = useState('');
  const [partnerData, setPartnerData] = useState('');

  const phoneRe = /^[0-9\b]+$/;

  const handleSendOTP = async () => {
    setLoading("Sending otp on mobile number");
    const data = await sendMobileOTP(contact_number, "false", "false");

    if (data) {
      setOTPRef(data.DataBaseKey);
      setisNewUser(data.new_partner);
      setPartnerData(data.partners);
      setCurrentPage(1.5);
    }

    setLoading('');
  };

  const handleAddDriver = async () => {
    setLoading("Saving Driver Details");
    const data = await addDriver(contact_number, "false", "false");
    if (data) {
      navigate('/addindividual/verifydl');
    }
    setLoading('');
  };

  const handleVerifyMobileOTP = async () => {
    if (mobileOTP.length < 6) {
      showErrorNotification('Please Enter the OTP');
      return;
    }

    setLoading('Verifying otp');

    const isVerified = await verifyMobileOTP(mobileOTP, contact_number, OTPRef);

    if (isVerified) {
      if (isNewUser) {
        handleAddDriver();
      } else {
        const payload = { ...partnerData };
        delete payload.isfleet;
        delete payload.isfleetdriver;
        delete payload.id;
        dispatch({ type: 'SET_PARTNER_DATA', payload });
        if (payload.aadhar_checked === true || payload.aadhar_checked === 'true') {
          navigate('/addindividual/verifyadhaar');
        } else {
          navigate('/addindividual/verifydl');
        }
      }
    } else {
      showErrorNotification('Invalid OTP');
    }
    setLoading('');
  };

  const onBack = useCallback(() => {
    if (currentPage === 1) {
      navigate('/registeroption');
    } else if (currentPage === 1.5) {
      setCurrentPage(1);
    }
  }, [currentPage, navigate]);

  const pageComponent = (
    <Box>
      <BackNav title="Adding new individual Partner" onBack={onBack} />
      <div className="mt-5 relative">
        <div className="absolute w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>
        <StepHeader step={1} text="Enter OTP and verify mobile number" />
      </div>
      {loading === '' ? (
        <div className="pb-16">
          {/* if page is 1 the show mobile number input form */}
          {currentPage === 1 && (
            <>
              <Typography component="div" variant="body2">
                Mobile number<span className="text-[#DC3545]">*</span>
              </Typography>
              <input
                type="tel"
                maxLength={10}
                minLength={10}
                value={contact_number}
                placeholder="Enter your mobile number"
                className="border border-gray-500 bg-gray-100 p-2 rounded-md w-full"
                onChange={(e) =>
                  (phoneRe.test(e.target.value) || e.target.value === '') &&
                  dispatch({
                    type: 'SET_DATA',
                    payload: {
                      name: 'contact_number',
                      value: e.target.value,
                    },
                  })
                }
              />
            </>
          )}
          {/* if page is 1.5 then show mobile number otp page */}
          {currentPage === 1.5 && (
            <div className="mb-4">
              <Typography component="div" variant="body2">
                Enter OTP sent to {contact_number}
                <span className="text-[#DC3545]">*</span>
              </Typography>
              <input
                type="tel"
                maxLength={6}
                value={mobileOTP}
                placeholder="Enter OTP sent to mobile number"
                className="border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={(e) =>
                  (phoneRe.test(e.target.value) || e.target.value === '') && setMobileOTP(e.target.value)
                }
              />
              <div className="mt-20">
                <img className="m-auto mb-3 translate-x-2" src={otpSvg} alt="" />
                <div className="text-gray-500 text-center">
                  <Typography component="div" variant="body2">
                    Enter OTP to verify your number
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoaderScreen message={loading} />
      )}
    </Box>
  );

  const bottomAction =
    loading === '' &&
    (currentPage === 1 ? (
      <SecondaryButton text="Send OTP" handleOnclick={handleSendOTP} />
    ) : (
      <SecondaryButton text="Continue" handleOnclick={handleVerifyMobileOTP} />
    ));
  return <PageTemplate bottomAction={bottomAction} pageComponent={pageComponent} />;
}

export default AddIndividual;
