import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import arrowLeft from '../../assets/ArrowLeft.svg';
import dlSvg from '../../assets/driving-license.svg';
import carSvg from '../../assets/car.svg';

import { useIndividualContext, useLocationContext, useFleetContext } from '../../context';

import { SecondaryButton, PageTemplate } from '../../components';
import RegisterCard from './RegisterCard';

function RegisterPartnerType() {
  const [partnerType, setPartnerType] = useState('Individual');
  const { dispatch: individualDispatch } = useIndividualContext();
  const { dispatch: FleetDispatch } = useFleetContext();
  const { selectedLocation } = useLocationContext();

  const navigate = useNavigate();

  const onProceed = useCallback(() => {
    const payload = {
      type: 'SET_DATA',
      payload: {
        name: 'selected_location',
        value: selectedLocation,
      },
    };
    if (partnerType === 'Individual') {
      individualDispatch(payload);
      navigate('/addindividual');
    } else {
      FleetDispatch(payload);
      navigate('/addfleet');
    }
  }, [FleetDispatch, individualDispatch, navigate, partnerType, selectedLocation]);

  const pageElement = (
    <Box>
      <NavLink to="/" className="w-max">
        <img src={arrowLeft} alt="back" className="w-9 h-9" />
      </NavLink>
      <div className="text-xl font-semibold mt-2">Choose how you want to earn</div>
      <div className="text-base text-gray-500 mb-8">Register New partner</div>
      <Stack spacing={2}>
        <RegisterCard
          text={'You wish to drive a car, which you may or may not own.'}
          title={'Individual'}
          handleOnClick={() => setPartnerType('Individual')}
          isActive={partnerType === 'Individual'}
          imageSrc={dlSvg}
        />
        <RegisterCard
          text={
            'You own a car that you wish to have driven either by your designated driver or by employing another person.'
          }
          title={'Fleet'}
          handleOnClick={() => setPartnerType('Fleet')}
          isActive={partnerType === 'Fleet'}
          imageSrc={carSvg}
        />
      </Stack>
    </Box>
  );

  return (
    <PageTemplate
      pageComponent={pageElement}
      bottomAction={<SecondaryButton text="Proceed" handleOnclick={onProceed} />}
    />
  );
}

export default RegisterPartnerType;
