const RegisterCard = ({ title, text, handleOnClick, imageSrc, isActive }) => {
  return (
    <div
      id={title.toLowerCase()}
      onClick={handleOnClick}
      className={
        'shadow-[0_0px_4px_0px_rgba(0,0,0,0.2)] w-full p-4 rounded-md flex justify-between h-24 items-center ' +
        (isActive ? 'border-2 border-black' : 'opacity-50') // Corrected the ternary operator placement
      }
    >
      <div className="w-full">
        <div className="text-base font-semibold">{title}</div>
        <div className="text-[13px]">{text}</div>
      </div>
      <div>
        <img src={imageSrc} alt="Fleet / Individual Driver Icon" className="h-full" />
      </div>
    </div>
  );
};

export default RegisterCard;
