import { Stack } from "@mui/material";

export const PageTemplate = ({ pageComponent, bottomAction }) => {
  return (
    <div className="w-screen h-screen bg-gray-100 overflow-scroll">
      <div className="bg-white flex flex-col h-full m-auto max-w-[1000px] min-h-screen p-5 w-full">
        <Stack height="100%" justifyContent="space-between">
          {pageComponent}
          {bottomAction}
        </Stack>
      </div>
    </div>
  );
};
