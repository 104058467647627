import React, { useState } from 'react';
import arrowLeft from '../../assets/ArrowLeft.svg';
import { useFleetContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import tickSvg from '../../assets/Group 427319018.svg';
import loadingGif from '../../assets/carLoading.gif';
import Modal from 'react-modal';
import crossSvg from '../../assets/Vector (1) copy.svg';
import axios from 'axios';
export default function AddFleetBankDetails() {
  const navigate = useNavigate();
  const {
    name,
    selected_location,
    contact_number,
    dlnumber,
    dob,
    dl_front_img,
    dl_back_img,
    dl_number_checked,
    dl_number_data,
    partner_photo,
    pan_no,
    pan_img,
    aadhaar_no,
    aadhaar_front_img,
    aadhaar_back_img,
    aadhar_data,
    aadhar_checked,
    is_agreed,
    bank_details,
    gst_number,
    bdCode,
    dispatch,
  } = useFleetContext();
  const { full_name, bank_name, account_type, account_no, branch_name, ifsc } = { ...bank_details };

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const updateDriver = async isAgreed => {
    setIsOpen(false);
    setLoading('Uploading your details...');
    console.log(contact_number);
    let driver_details = {
      contact_number: contact_number,
      id: contact_number,
      isfleet: 'true',
      isfleetdriver: 'false',
      bank_details: bank_details,
      gst_number: gst_number,
      is_agreed: isAgreed,
      bdCode: bdCode,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/updateDriver`, driver_details);
      dispatch({
        type: 'SET_DATA',
        payload: { name: 'is_agreed', value: isAgreed },
      });
      setLoading(false);
      console.log(response);
      if (isAgreed) {
        navigate('/addfleet/dashboard');
      } else {
        setIsOpen(true);
      }

      console.log(driver_details);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChange = (e, name) => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        name: 'bank_details',
        value: { ...bank_details, [name]: e.target.value },
      },
    });
  };
  const customStyles = {
    content: {
      inset: '50% -100px -100px 50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      maxHeight: '100%',
      maxWidth: '400px',
    },
    overlay: {
      background: 'rgb(0 0 0 / 70%)',
      zIndex: '3',
    },
  };
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
          setIsOpen(true);
        }}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className=" p-5">
          <div className="flex justify-between items-center">
            <div className="flex-1 text-base font-semibold">Terms and Conditions</div>
            <img
              className=" cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
              src={crossSvg}
              alt=""
            />
          </div>
          <div>
            <h1 className="mt-4">PRIVACY</h1>
            <p className=" text-xs">
              Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.
            </p>
          </div>
          <div>
            <h1 className="mt-4">LINKED SITES</h1>
            <p className=" text-xs">
              This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked
              Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control,
              and we are not responsible for and does not endorse the content of such Linked Sites, including any
              information or materials contained on such Linked Sites. You will need to make your own independent
              judgment regarding your interaction with these Linked Sites.
            </p>
          </div>
          <div className=" pb-10">
            <h1 className="mt-4"> FORWARD LOOKING STATEMENTS</h1>
            <p className=" text-xs">
              All materials reproduced on this site speak as of the original date of publication or filing. The fact
              that a document is available on this site does not mean that the information contained in such document
              has not been modified or superseded by events or by a subsequent document or filing. We have no duty or
              policy to update any information or statements contained on this site and, therefore, such information or
              statements should not be{' '}
            </p>
          </div>
        </div>

        <div className=" sticky bottom-0 p-4 bg-white border w-full">
          <button
            className="rounded-md bg-black text-white py-[6px] w-full"
            onClick={() => {
              updateDriver(true);
            }}
          >
            Accept and Continue
          </button>
        </div>
      </Modal>
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
          <div className="flex items-center gap-2">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => {
                navigate('/addindividual/verifyrc');
              }}
            />
            <div className=" text-base font-semibold">Adding new individual Partner</div>
          </div>
          <div className="mt-5 relative">
            <div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Enter your basic details</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Hi {name}, Let's complete your kyc</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <div className="  bg-yellow w-8 py-1 rounded-sm text-center">3</div>
              <div className=" text-sm">Enter Bank details and GST No.</div>
            </div>
          </div>
          {/* <div className="mt-5 relative">
                        <div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

                        <div className="flex gap-3 items-center my-5 z-[1] relative">
                            <img src={tickSvg} className="ml-1" alt="" />
                            <div className=" text-sm">Enter and verify mobile number</div>
                        </div>
                        <div className="flex gap-3 items-center my-5 z-[1] relative">
                            <img src={tickSvg} className="ml-1" alt="" />
                            <div className=" text-sm">Enter and verify driver license</div>
                        </div>
                        <div className="flex gap-3 items-center my-5 z-[1] relative">
                            <img src={tickSvg} className="ml-1" alt="" />
                            <div className=" text-sm">
                                Hi {name}, Let's complete your kyc
                            </div>
                        </div>
                        <div className="flex gap-3 items-center my-5 z-[1] relative">
                            <img src={tickSvg} className="ml-1" alt="" />
                            <div className=" text-sm">Let's add your car details</div>
                        </div>
                        <div className="flex gap-3 items-center my-5 z-[1] relative">
                            <div className="  bg-yellow w-8 py-1 rounded-sm text-center">
                                3
                            </div>
                            <div className=" text-sm">Enter Bank details and GST No.</div>
                        </div>
                    </div> */}

          {!loading ? (
            <div className="pb-16">
              <div className=" text-xs mb-1 mt-3">Full Name</div>
              <input
                type="text"
                placeholder="Enter your name"
                value={full_name}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  handleChange(e, 'full_name');
                }}
              />
              <div className=" text-xs mb-1 mt-3">Bank Name</div>
              <input
                type="text"
                placeholder="Enter Bank name"
                value={bank_name}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  handleChange(e, 'bank_name');
                }}
              />
              <div className="mt-3">
                <input
                  type="radio"
                  name="account_type"
                  className="gender mr-2 scale-[1.4] accent-black"
                  id="saving"
                  checked="true"
                  value="saving"
                  onChange={e => {
                    handleChange(e, 'account_type');
                  }}
                />
                <label htmlFor="saving" className="mr-6">
                  Saving
                </label>
                <input
                  type="radio"
                  name="account_type"
                  className="gender mr-2 scale-[1.4] accent-black"
                  id="current"
                  value="current"
                  onChange={e => {
                    handleChange(e, 'account_type');
                  }}
                />
                <label htmlFor="current" className="mr-2">
                  Current
                </label>
              </div>
              <div className=" text-xs mb-1 mt-3">Bank Account No.</div>
              <input
                type="text"
                placeholder="Enter Account No."
                value={account_no}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  handleChange(e, 'account_no');
                }}
              />
              <div className=" text-xs mb-1 mt-3">Branch name</div>
              <input
                type="text"
                placeholder="Enter Branch name"
                value={branch_name}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  handleChange(e, 'branch_name');
                }}
              />
              <div className=" text-xs mb-1 mt-3">IFSC</div>
              <input
                type="text"
                placeholder="Enter IFSC Number"
                value={ifsc}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  handleChange(e, 'ifsc');
                }}
              />
              <div className=" text-xs mb-1 mt-3">GST Number (if any)</div>
              <input
                type="text"
                placeholder="Enter GST Number"
                value={gst_number}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  dispatch({
                    type: 'SET_DATA',
                    payload: { name: 'gst_number', value: e.target.value },
                  });
                }}
              />
              <div className=" text-xs mb-1 mt-3">Business Development Code</div>
              <input
                type="text"
                placeholder="BD Code"
                value={bdCode}
                className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                onChange={e => {
                  dispatch({
                    type: 'SET_DATA',
                    payload: { name: 'bdCode', value: e.target.value },
                  });
                }}
              />
            </div>
          ) : (
            <div className=" w-[250px] mx-auto mt-20">
              {/* if loading then show loading gif */}
              <img className="w-[165px] h-[130px] mx-auto" src={loadingGif} alt="loading..." />
              <div className=" whitespace-normal text-xs text-gray-500 text-center">{loading}</div>
            </div>
          )}
          {loading === false && (
            <div className="fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2">
              <button
                onClick={() => {
                  updateDriver(false);
                }}
                className=" bg-black text-white rounded-md px-8 py-2"
              >
                Complete
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
