import React, { useState } from 'react';
import Entity from '../components/EntityContext';
import { useNavigate } from 'react-router-dom';

export default function AddVehicle() {
  const [rcnumber, setRcNumber] = useState(null);
  const [rcchecked, setRcChecked] = useState(false);
  const [errormsg, setErrorMsg] = useState('');
  const { entity, setEntity } = Entity();

  const navigate = useNavigate();

  async function addVehicle() {
    if (rcchecked == true) {
      const url = ``; //url for Firebase AddVehicle Function
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: entity,
        });
        if (response.status === 200) {
          console.log('Vehicle Added');
          navigate('/adddriver');
        } else console.log('error');
      } catch {
        console.log('error');
      }
    } else {
      setErrorMsg('Kindly Verify RC Number before Proceeding');
    }
  }

  async function handleAddVehicle() {
    const url = ``;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          id_number: rcnumber,
        },
      });
      if (response.success === true) {
        setEntity({
          key_dl: localStorage.getItem('dlnumber'),
          data: response.data,
        });
        setRcChecked(true);
      } else console.log('error');
    } catch {
      console.log('error');
    }
  }

  return (
    <div className="p">
      <div className="p">
        <label>RC Number/ Vehicle Number</label>
        <input type="text" onChange={e => setRcNumber(e)} />
        <button type="button" onClick={handleAddVehicle}>
          Add Vehicle
        </button>
        {errormsg ? <p>{errormsg}</p> : null}
        <button type="button" onClick={addVehicle}>
          Proceed
        </button>
      </div>
    </div>
  );
}
