import axios from 'axios';

import { showErrorNotification, showSuccessNotification } from '../components';

export const addDriver = async (contact_number, isfleet, isfleetdriver) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addDriver`, {
      contact_number,
      id: contact_number,
      isfleet,
      isfleetdriver,
    });
    showSuccessNotification('Driver Data added');
    return response;
  } catch (e) {
    showErrorNotification('Error Occured. Please try again');
  }
};

export const getDriverDetails = async userId => {
  // let response;
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/getDriverDetails`, { id: userId });
    return response;
  } catch (error) {
    console.log(error);
    console.log(error.response.data.error === 'Driver not found');
    if (error.response.data.error === 'Driver not found') {
      showErrorNotification('Could not fetch driver details');
      return error.response.data.error;
    }
    return error;
  }
};
