import axios from 'axios';
import { showErrorNotification } from '../components';

export const sendSms = async contactNumber => {
  if (contactNumber.length < 10 || contactNumber.length > 10) {
    showErrorNotification('Invalid mobile number');
    return;
  }
  try {
    await axios.post(`${process.env.REACT_APP_BASE_URL}/driverOnBoardingSMS`, {
      contact_number: `${'+91' + contactNumber}`,
    });
  } catch (error) {
    showErrorNotification('Error in sending SMS');
  }
};
