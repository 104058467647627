import { Typography } from '@mui/material';
import arrowLeft from '../assets/ArrowLeft.svg';

export const BackNav = ({ title, onBack }) => {
  return (
    <div className="flex items-center gap-2">
      <img src={arrowLeft} alt="Back Arrow" onClick={onBack} />
      <Typography component="h1" variant="h6" fontWeight="600">
        {title}
      </Typography>
    </div>
  );
};
