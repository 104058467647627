import React from 'react';
import cloudSvg from '../assets/CloudArrowUp.svg';
import fileSvg from '../assets/Vector (1).svg';
import delSvg from '../assets/Vector (2).png';
function ImageInput({ handleChange, id, image, labelText, imgText, handleDel }) {
  return (
    <>
      <input type="file" className="hidden" onInput={e => handleChange(e)} id={id} accept="image/*" />
      {!image ? (
        <label
          htmlFor={id}
          className="border-2 border-gray-400 border-dashed rounded-md flex flex-col justify-center items-center h-24 w-full max-w-[500px] mt-4"
        >
          <img src={cloudSvg} alt="" />
          <div className="text-base text-gray-500">{labelText}</div>
        </label>
      ) : (
        <div className="flex w-full items-center rounded-md bg-gray-100 p-2 mt-4 max-w-[500px] gap-3">
          <img src={fileSvg} alt="" />
          <div className=" flex-1">{imgText}</div>
          <img
            className=" cursor-pointer"
            onClick={() => {
              handleDel();
              document.getElementById(id).value = '';
            }}
            src={delSvg}
            alt=""
          />
        </div>
      )}
    </>
  );
}

export default ImageInput;
