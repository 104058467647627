import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFleetContext } from '../../context';
import arrowLeft from '../../assets/ArrowLeft.svg';
import axios from 'axios';
import otpSvg from '../../assets/Group 427319036.jpg';
import dlSvg from '../../assets/Group.svg';
import loadingGif from '../../assets/carLoading.gif';
import Modal from 'react-modal';
import Error from '../../components/Error';

function AddFleet() {
  const navigate = useNavigate();
  const { contact_number, name, email, gender, dispatch, selected_location } = useFleetContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState(true);
  const [mobileOTP, setMobileOTP] = useState('');
  const [existpartner, setExistPartner] = useState('');
  const [OTPRef, setOTPRef] = useState('');
  const [error, setError] = useState('');

  const phoneRe = /^[0-9\b]+$/;

  const sendOTPMobile = async () => {
    if (contact_number.length < 10) {
      setError('Invalid mobile number');
      return;
    }
    setLoading('Sending otp on mobile number');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/sendPartnerOTP`, {
        contact_number: contact_number,
        isfleet: 'true',
        isfleetdriver: 'false',
      });

      console.log(response);
      setOTPRef(response.data.DataBaseKey);
      setNewUser(response.data.new_partner);
      setExistPartner(response.data.partners);
      // newUser = true;
      setCurrentPage(1.5);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.response?.status === 400) {
        setError('This number is already used in individual registration');
      } else {
        setError('OTP API error');
      }
    }
  };
  const addPartner = async () => {
    setLoading('Saving contact info');
    try {
      let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addDriver`, {
        contact_number: contact_number,
        id: contact_number,
        name: name,
        email: email,
        gender: gender,
        isfleet: 'true',
        isfleetdriver: 'false',
      });
      console.log(response);
      setLoading(false);
      navigate('/addfleet/fleetverifyadhaar');
    } catch (e) {
      setError('Some Error Occured');
      setLoading(false);
      console.log(e);
    }
  };
  const verifyMobileOTP = async () => {
    setLoading('Verifying otp');
    try {
      let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyPhoneNumber`, {
        otp: mobileOTP,
        phoneNumber: contact_number,
        DataBaseKey: OTPRef,
      });
      console.log(response);
      if (response.data.message == 'OTP is correct') {
        if (newUser == true) {
          addPartner();
        } else if (existpartner.aadhaar_checked === true || existpartner.aadhaar_checked === 'true') {
          setLoading(false);
          navigate('/addfleet/dashboard');
        } else {
          setLoading(false);
          navigate('/addfleet/fleetverifyadhaar');
        }
        console.log(response.data.message);
      } else {
        console.log('Invalid otp');
        setLoading(false);
        setError('Invalid OTP');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Some Error Occurred');
    }
  };
  return (
    <>
      <Error errorMessage={error} setErrorMessage={setError} />
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
          <div className="flex items-center gap-2">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => {
                if (currentPage == 1) {
                  navigate('/registeroption');
                } else if (currentPage == 1.5) {
                  setCurrentPage(1);
                }
              }}
            />
            <div className=" text-base font-semibold">Adding new individual Partner</div>
          </div>
          <div className="mt-5 relative">
            <div className="absolute w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <div className="  bg-yellow w-8 py-1 rounded-sm text-center">1</div>
              <div className=" text-sm">Enter your basic details</div>
            </div>
          </div>
          {loading === false ? (
            <div className=" pb-16">
              {/* if page is 1 the show mobile number input form */}
              {currentPage == 1 && (
                <div className=" mb-4">
                  <div className=" text-xs mb-1">
                    Full Name<span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter your Name"
                    // value={dlnumber}
                    className=" border mb-4 border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={e => dispatch({ type: 'SET_DATA', payload: { name: 'name', value: e.target.value } })}
                  />
                  <div className=" text-xs mb-1">
                    Mobile number<span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="tel"
                    maxLength={10}
                    value={contact_number}
                    placeholder="Enter your mobile number"
                    className=" border mb-4 border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={e =>
                      (phoneRe.test(e.target.value) || e.target.value === '') &&
                      dispatch({ type: 'SET_DATA', payload: { name: 'contact_number', value: e.target.value } })
                    }
                  />
                  <div className=" text-xs mb-1">
                    Email ID<span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="email"
                    placeholder="Enter your Email ID"
                    // value={dlnumber}
                    className=" border mb-4 border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={e => dispatch({ type: 'SET_DATA', payload: { name: 'email', value: e.target.value } })}
                  />
                  <div className="">
                    <input
                      type="radio"
                      name="gender"
                      className="gender mr-2 scale-[1.4] accent-black"
                      id="male"
                      value="male"
                      onChange={e => dispatch({ type: 'SET_DATA', payload: { name: 'gender', value: e.target.value } })}
                    />
                    <label htmlFor="male" className="mr-6">
                      Male
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      className=" accent-black gender mr-2 scale-[1.4]"
                      id="female"
                      value="female"
                      onChange={e => dispatch({ type: 'SET_DATA', payload: { name: 'gender', value: e.target.value } })}
                    />
                    <label htmlFor="female" className="mr-2">
                      Female
                    </label>
                  </div>
                </div>
              )}
              {/* if page is 1.5 then show mobile number otp page  */}
              {currentPage == 1.5 && (
                <div className=" mb-4">
                  <div className=" text-xs mb-1">
                    Enter OTP<span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="tel"
                    maxLength={6}
                    value={mobileOTP}
                    placeholder="Enter OTP sent to mobile number"
                    className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={e =>
                      (phoneRe.test(e.target.value) || e.target.value === '') && setMobileOTP(e.target.value)
                    }
                  />
                  <div className="mt-20">
                    <img className="m-auto mb-3 translate-x-2" src={otpSvg} alt="" />
                    <div className="text-xs text-gray-500 text-center">Enter OTP to verify your Number</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className=" w-[250px] mx-auto mt-20">
              {/* if loading then show loading gif */}
              <img className="w-[165px] h-[130px] mx-auto" src={loadingGif} alt="" />
              <div className=" whitespace-normal text-xs text-gray-500 text-center">{loading}</div>
            </div>
          )}
          {loading === false && (
            <div className="fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2">
              {currentPage == 1 && (
                <button onClick={sendOTPMobile} className=" bg-black text-white rounded-md px-8 py-2">
                  Send OTP
                </button>
              )}
              {currentPage == 1.5 && (
                <button onClick={verifyMobileOTP} className=" bg-black text-white rounded-md px-8 py-2">
                  Continue
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddFleet;
